import React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import Allppm from '../components/Allppm'
import '../components/about.css'

const IsridPPM = ({
  data: {
    allStrapiPpmPages: { nodes: ppmlist },
  },
  location,
}) => {
  return (
    <Layout location={location} crumbLabel="Bylaws">
      <div className="container">
        <div className="box">
          <div className="single-post">
            {' '}
            {ppmlist.map((ppmlists, index) => {
              return <Allppm key={index} {...ppmlists} />
            })}{' '}
          </div>{' '}
        </div>{' '}
      </div>{' '}
    </Layout>
  )
} 
export const query = graphql`
  {
    allStrapiPpmPages {
      nodes {
        PPM_Page_Title
        PPM_Card_Table
        PPM_Card_Content
        PPM_Title_Alignment
        PPM_Title_Color {
          color
        }
        PPM_Title_Family {
          family
        }
        PPM_Title_Size {
          size
        }
        PPM_Title_Weight {
          weight
        }
        PPM_Card_BG_color {
        color
      }
        
        
      }
    }
  }
`
export default IsridPPM
