import React from 'react'
import './ppm.css'
import ReactMarkdown from 'react-markdown'
import MDEditor from '@uiw/react-md-editor';

const Allppm = ({
  PPM_Page_Title,
  PPM_Card_Content,
  PPM_Title_Color,
  PPM_Title_Family,
  PPM_Title_Size,
  PPM_Title_Weight,
  PPM_Title_Alignment,
  PPM_Card_BG_color,
  PPM_Card_Table,
}) => {
  return (
    <div>
      <div className="ppm_edit"
      style={{backgroundColor: PPM_Card_BG_color.color}}>
          <p 
          style={{
                    fontFamily: PPM_Title_Family.family,
                    color: PPM_Title_Color.color,
                    fontSize: PPM_Title_Size.size,
                    fontWeight: PPM_Title_Weight.weight,
                    textAlign: PPM_Title_Alignment,
                    }}>{PPM_Page_Title}</p>

          <div >
            <p className="edit">
                <ReactMarkdown 
                    source={PPM_Card_Content}
                />
            </p>
            <table>
                <ReactMarkdown 
                    source={PPM_Card_Table}
                />
            </table>
          </div> 
      </div>
    </div>
  )
}

export default Allppm
